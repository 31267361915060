import React, { useState } from 'react';
import SortableTable from 'reactstrap-sortable-table';
import { initColumns } from '../../utils/helper';

const ProductionsTab = ({tabProductions}) => {

    const [productions, setProductions] = useState(tabProductions)

    return (
        <>
            {productions && productions.length > 0 ?
                <SortableTable
                    responsive
                    data={productions}
                    setData={setProductions}
                    columns={initColumns(productions, ['id', 'leaseId', 'operatorId'])}
                    addProps={{
                        tHeading: { className: 'px-2', style: { whiteSpace: 'nowrap', } },
                    }}
                />
                :
                <div className="px-2">
                    <p><i>No data found.</i></p>
                </div>
            }
        </>
    );
};

export default ProductionsTab;