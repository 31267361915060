import http from './http'
import { getToken } from './auth'

const base = 'CadUploads'

export async function getCadUploads() {

    const { data } = await http.get(`${base}/`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}