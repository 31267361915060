import React, { useEffect, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import OverlayLoader from '../../components/overlayLoader';
import { getLeaseById } from '../../services/productions';
import { onQuerySuccess } from '../../utils/queryResponse';
import OwnerTab from './ownerTab';
import ProductionsTab from './productionsTab';

const LeasePage = (props) => {

    let { id } = useParams();
    const params = new URLSearchParams(window.location.search)
    
    const county = params.get('county')
    const leaseName = params.get('leaseName')
    const leaseNumber = params.get('leaseNumber')
    const startDate = params.get('startDate')
    const endDate = params.get('endDate')

    const [lease, setLease] = useState()

    const { data, isFetching } = useQuery(['lease', id], () => getLeaseById(id, startDate, endDate))

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            console.log(result)
            setLease(result)
        })
    }, [data])

    return (
        <div>

            <Card className="mb-3">
                <Card.Body>
                    <p className="mb-0"><span className="text-bold">County</span>: {county}</p>
                    <p className="mb-0"><span className="text-bold">Lease Name</span>: {leaseName}</p>
                    <p className="mb-0"><span className="text-bold">Lease Number</span>: {leaseNumber}</p>
                </Card.Body>
            </Card>

            <Card>
                <Card.Body>
                    {lease ?
                        <Tabs defaultActiveKey={"monthly"} className="mb-3">
                            <Tab eventKey="monthly" title="Monthly" mountOnEnter>
                                <ProductionsTab tabProductions={lease?.perMonth} />
                            </Tab>
                            <Tab eventKey="yearly" title="Yearly" mountOnEnter>
                                <ProductionsTab tabProductions={lease?.perYear} />
                            </Tab>
                            <Tab eventKey="operator-history" title="Operator History" mountOnEnter>
                                <ProductionsTab tabProductions={lease?.perOperator} />
                            </Tab>
                            <Tab eventKey="owners" title="Owners" mountOnEnter>
                                <OwnerTab tabOwners={lease?.owners} />
                            </Tab>
                        </Tabs>
                        :
                        <div style={{ height: '100px' }}>
                            <OverlayLoader />
                        </div>
                    }
                </Card.Body>
            </Card>
        </div>
    );
};

export default LeasePage;