import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import FormInput from '../../components/forms/formInput';
import { updateOwner } from '../../services/owners';
import { onMutateSuccess } from '../../utils/queryResponse';
import { toast } from 'react-toastify';

const OwnerSetModal = ({ display, toggle, owner, setOwner }) => {

    const [_owner, _setOwner] = useState({})

    const { mutate, isLoading } = useMutation(updateOwner, {
        onSuccess: onMutateSuccess(() => {
            setOwner(_owner)
            toast.success(' Successfully updated owner ', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            onHide()
        })
    })

    const onShow = () => {
        _setOwner(owner)
    }

    const onHide = () => {
        toggle(false)
    }

    const handleChange = (objValue) => {
        const newOwner = { ..._owner, ...objValue }
        _setOwner(newOwner)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        console.log(_owner)
        mutate(_owner)
    }


    return (
        <Modal
            show={display}
            onHide={onHide}
            onShow={onShow}
            size="lg"
            centered
        >

            <Modal.Body className='pb-1' >
                <h4 className="text-center mt-3">Edit Owner</h4>

                <hr />

                <Form id='set-owner' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>

                    <Row>
                        <Col lg="6">
                            <FormInput className="mb-3" type='text' label="Name" placeholder='Enter Name'
                                value={_owner?.name} onChange={(e) => handleChange({ name: e.target.value })}
                            />
                        </Col>
                        {/* <Col>
                            <FormInput className="mb-3" type='number' label="Number" placeholder='Enter Number'
                                value={_owner?.number} onChange={(e) => handleChange({ number: e.target.value })}
                            />
                        </Col> */}
                    </Row>

                    <FormInput className="mb-3" type='text' label="Address" placeholder='Enter Address'
                        value={_owner?.address} onChange={(e) => handleChange({ address: e.target.value })}
                    />

                    <Row>
                        <Col lg="4">
                            <FormInput className="mb-3" type='text' label="Phone No." placeholder='Enter Phone No.'
                                value={_owner?.phone} onChange={(e) => handleChange({ phone: e.target.value })}
                            />
                        </Col>
                        <Col lg="4">
                            <FormInput className="mb-3" type='email' label="Email" placeholder='Enter Email'
                                value={_owner?.email} onChange={(e) => handleChange({ email: e.target.value })}
                            />
                        </Col>
                        <Col>
                            <FormInput className="mb-3" type='text' label="Attn." placeholder='Enter Attn.'
                                value={_owner?.attn} onChange={(e) => handleChange({ attn: e.target.value })}
                            />
                        </Col>
                    </Row>

                    <FormInput as="textarea" rows={5} className="mb-3" type='text' label="Note" placeholder='Enter Note'
                        value={_owner?.note} onChange={(e) => handleChange({ note: e.target.value })}
                    />

                    <div className="mb-4">
                        <Form.Label className="me-2">Returned Envelope</Form.Label>
                        <Form.Check inline checked={_owner?.returnedEnvelope} className="mb-2 me-2" onChange={(e) => handleChange({ returnedEnvelope: e.target.checked })} />
                    </div>

                    <hr />
                    <div className='d-flex justify-content-end'>
                        <Button className="me-2" variant="light" onClick={onHide}>Cancel</Button>
                        <Button type='submit' disabled={isLoading}>
                            {isLoading ? 'Submitting' : 'Submit'}
                        </Button>
                    </div>

                </Form>

            </Modal.Body>
        </Modal>
    );
};

export default OwnerSetModal;