import React from 'react'
import { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';

const Pages = ({ paginationData:{ pageNumber,totalPages, nextPage, previousPage }, changePage }) => {

    const [itemNumbers, setItemNumbers] = useState([])

    useEffect(() => {
        let array = []
        for (
            let number = pageNumber > 1 ? (pageNumber-1) : 1; 
            number <= (pageNumber < totalPages ? (pageNumber+1) : totalPages); 
            number++
        ) {
            array.push(number)
        }
        setItemNumbers(array)
        return () => {}
    }, [pageNumber, totalPages])

    const onChangePageHandler = (page) => {
        if(pageNumber !== page) {
            changePage(page)
        }
    }

    return (
        <Pagination className="justify-content-center ">

            <Pagination.First disabled={ previousPage === null || previousPage === undefined } key={`page-first`} onClick={() => onChangePageHandler(1)} title="first page" />
            <Pagination.Prev disabled={ previousPage === null || previousPage === undefined } key={`page-prev`} onClick={() => onChangePageHandler(pageNumber - 1)} title="previous page" />
            { totalPages > 3 && pageNumber > 2 &&
                <Pagination.Ellipsis />
            }
            { itemNumbers.map((itemNumber) => (
                <Pagination.Item onClick={() => onChangePageHandler(itemNumber)} key={`page-${itemNumber}`} active={itemNumber === pageNumber}>
                        {itemNumber}
                </Pagination.Item>
            ))}
            { totalPages > 3 && pageNumber < totalPages-1 &&
                <Pagination.Ellipsis />
            }
            <Pagination.Next disabled={ nextPage === null || nextPage === undefined } key={`page-next`} onClick={() => onChangePageHandler(pageNumber + 1)} title="next page" />
            <Pagination.Last disabled={ nextPage === null || nextPage === undefined } key={`page-last`} onClick={() => onChangePageHandler(totalPages)} title="last page" />

        </Pagination>
    )
}

export default Pages