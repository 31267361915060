import http from './http'
import { getToken } from './auth'

const base = 'Leases'

export async function searchLeases(searchText) {

    const { data } = await http.get(`${base}/search/${searchText}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function getLeaseById(id) {

    const { data } = await http.get(`${base}/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        }
    })
    return data
}

export async function getLeasesByIds(ids) {

    const params = new URLSearchParams()

    console.log(ids)
    if (ids) {
        const _ids = ids instanceof Array ? ids : ids.split(',')
        _ids.forEach(element => {
            params.append('ids', element)
        });
    }

    const { data } = await http.get(`${base}/get-multi`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        },
        params
    })
    return data
}