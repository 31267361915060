import { getToken } from './auth'
import http from "./http"

const base = 'Operators'

export async function searchOperators(searchText) {

    const { data } = await http.get(`${base}/search/${searchText}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function getOperatorsByIds(ids) {

    const params = new URLSearchParams()

    console.log(ids)
    if (ids) {
        const _ids = ids instanceof Array ? ids : ids.split(',')
        _ids.forEach(element => {
            params.append('ids', element)
        });
    }

    const { data } = await http.get(`${base}/get-multi`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        },
        params
    })
    return data
}