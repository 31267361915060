import jwtDecode from 'jwt-decode'
import http from './http'

const base = 'Auth'

export async function login(account) {
  return await http.post(base + '/login', account)
}

export const logout = () => {
  localStorage.removeItem('dn-saas-token')
}

export function getCurrentUser() {
    try {
      const token = localStorage.getItem('dn-saas-token')
      return jwtDecode(token)
    } catch (error) {
      return null
    }
  }
  
  export function getToken() {
    return localStorage.getItem('dn-saas-token')
  }