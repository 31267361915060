import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/images/logo_hor.png';

const Sidebar = ({ sidebarToggle, setSidebarToggle, currentUser }) => {

    const photoBaseUrl = process.env.REACT_APP_API_BASE_URL + 'files/profile-photos/'

    return (
        <nav className={`d-md-none col-md-3 col-lg-2 sidebar px-0 ${sidebarToggle ? 'expanded' : ''}`}>
            <div onClick={() => setSidebarToggle(false)} className="sidebar-close d-block d-md-none px-3 text-muted"><i className="fas fa-times"></i></div>
            <div className="sidebar-sticky">

                <div className="sidebar-top mb-4">
                    <div className="center-xy p-3">
                        <Link to='/'><img className="logo-img" src={logo} alt="sags logo" /></Link>
                    </div>
                </div>

                <ul className="nav flex-column mb-3">
                    <NavLink to='/home'>
                        <li><span className="nav-link cursor-pointer"><div className="px-2 py-1 rounded"><i className="fa-solid fa-home"></i> Home</div></span></li>
                    </NavLink>
                </ul>

                {/* <p className="sidebar-menu-header mb-1 text-muted"><small>Links</small></p> */}

                <ul className="nav flex-column">
                    <NavLink to='/uploads/cad'>
                        <li><span className="nav-link cursor-pointer"><div className="px-2 py-1 rounded"> CAD</div></span></li>
                    </NavLink>
                    <NavLink to='/uploads/monthly-oil-price'>
                        <li><span className="nav-link cursor-pointer"><div className="px-2 py-1 rounded"> Prices</div></span></li>
                    </NavLink>
                </ul>


                <div className="bg-white sidebar-bottom w-100 mb-3">

                    <div className="px-3 py-2 d-flex rounded bg-lightgrey mx-4 mb-3">
                        <div className="align-self-center">
                            {currentUser?.Photo ?
                                <img src={photoBaseUrl + currentUser?.Photo} alt="profile" className="userphoto me-2" />
                                :
                                <span className="userphoto-placeholder me-2"></span>
                            }
                        </div>
                        <div className="center-xy" style={{height:'auto'}}>
                           <span>{currentUser?.FirstName} {currentUser?.LastName}</span>
                        </div>
                    </div>

                    <ul className="nav flex-column">
                        <NavLink to='/admin' className="mb-0">
                            <li><span className="nav-link cursor-pointer"><div className="px-2 py-1 rounded"><i className="fa-solid fa-power-off"></i>Log Out</div></span></li>
                        </NavLink>
                    </ul>
                </div>

            </div>

        </nav>
    )
}

export default Sidebar;