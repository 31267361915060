import http from './http'
import { getToken } from './auth'

const base = 'Users'

export async function getUsers() {

    const { data } = await http.get(`${base}/`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function createUser(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    const { data } = await http.post(base + '/register', payload, config)
    return data
}

export async function deleteSystemAdminUser(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.delete('system-admin/' + base + '/' + id, config)
    return data
}

export async function verifyAccount(code) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }

    const { data } = await http.post(base + '/verify-account/' + code, {}, config)
    return data
}

export async function resendVerificationCode() {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }

    const { data } = await http.post(base + '/resend-verify-code', {}, config)
    return data
}