import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import NotFoundPage from './pages/404';
import SystemIssuesPage from './pages/admin/systemIssues';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import OwnerPage from './pages/owners/owner';
import OwnersPage from './pages/owners/owners';
import LeasePage from './pages/productions/lease';
import OperatorPage from './pages/productions/operator';
import RegisterPage from './pages/register';
import ThankYouPage from './pages/thankyou';
import CADPage from './pages/uploads/cad';
import MonthlyOilPricePage from './pages/uploads/prices';
import DashboardTemplate from './templates/dashboard';

function App(props) {
  return (
    <>
      <ToastContainer />
      <Routes>
        {/* <Route path="/" element={<DashboardTemplate />} /> */}

        <Route element={<DashboardTemplate />}>
          <Route path="owner/:id" element={<OwnerPage />} />
          <Route path="owners" element={<OwnersPage />} />
          <Route path="productions/operator/:id" element={<OperatorPage />} />
          <Route path="productions/lease/:id" element={<LeasePage />} />
          <Route path="uploads/prices" element={<MonthlyOilPricePage />} />
          <Route path="uploads/cad" element={<CADPage />} />
          <Route path="system-issues" element={<SystemIssuesPage />} />
          <Route index element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
          {/* <Route path="profile" element={<ProfilePage />} />
          <Route path="incomes" element={<IncomesPage />} />
          <Route path="expenses" element={<ExpensesPage />} />
          <Route path="cash-flow" element={<CashFlowPage />} />
          <Route path="payroll" element={<PayrollMenuPage />} />
          <Route path="payroll/view" element={<ViewPayrollPage />} />
          <Route path="payroll/add" element={<SetPayrollPage />} />
          <Route path="payroll/edit/:id" element={<SetPayrollPage />} />
          <Route path="payroll/report" element={<PayrollReportPage />} /> */}

          {/* Admin Pages */}
          {/* <Route path="admin"element={<AdminPage />} />
          <Route path="admin/users"element={<UsersPage />} /> */}

          <Route
            path="*"
            element={
              <NotFoundPage />
            }
          />
        </Route>
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />

      </Routes>
    </>
  );
}

export default App;