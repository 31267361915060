import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import DatePicker from '../../components/forms/formDatePicker';
import FormInput from '../../components/forms/formInput';
import { addPrice } from '../../services/prices';
import { onMutateSuccess } from '../../utils/queryResponse';

const initPrice = {
    date: new Date(),
    price: ''
}

const PriceSetModal = ({ display, toggle }) => {

    const [price, setPrice] = useState(initPrice)

    const queryClient = useQueryClient()
    const { mutate, isLoading } = useMutation(addPrice, {
        onSuccess: onMutateSuccess(() => {
            toast.success(' Successfully updated price ', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            queryClient.invalidateQueries('prices')
            onHide()
        })
    })

    const onShow = () => {
        setPrice(initPrice)
    }

    const onHide = () => {
        toggle(false)
    }

    const handleChange = (objValue) => {
        const newPrice = { ...price, ...objValue }
        setPrice(newPrice)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        console.log(price)
        mutate(price)
    }


    return (
        <Modal
            show={display}
            onHide={onHide}
            onShow={onShow}
            centered
        >

            <Modal.Body className='pb-1' >
                <h4 className="text-center mt-3">Add Price</h4>

                <hr />

                <Form id='set-price' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>

                    <Row>
                        <Col lg="6">
                            <DatePicker className='form-control' label='Date From' placeholderText='Filter Date From' showMonthYearPicker dateFormat="MM/yyyy"
                                selected={price?.date} onChange={(date) => handleChange({ date: date })}
                            />
                        </Col>
                        <Col>
                            <FormInput className="mb-3" type='number' label="Price" placeholder='Enter Price'
                                value={price?.price} onChange={(e) => handleChange({ price: e.target.value })}
                            />
                        </Col>
                    </Row>

                    <hr />
                    <div className='d-flex justify-content-end'>
                        <Button className="me-2" variant="light" onClick={onHide}>Cancel</Button>
                        <Button type='submit' disabled={isLoading}>
                            {isLoading ? 'Submitting' : 'Submit'}
                        </Button>
                    </div>

                </Form>

            </Modal.Body>
        </Modal>
    );
};

export default PriceSetModal;