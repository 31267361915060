import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getCounties } from '../../services/counties';
import { onQuerySuccess } from '../../utils/queryResponse';
import FormSelect from '../forms/formSelect';

const CountySelect = ( {value, onChange, isMulti, ...rest} ) => {

    const [options, setOptions] = useState([])

    const { data, isLoading } = useQuery('counties-options', getCounties, {
        staleTime: 1000 * 60 * 2, // 2 minute
        refetchOnMount: true,
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setOptions(result.map(d => ({
                value: d.id, label: d.name
            })))
        })
    }, [data])

    return (
        <FormSelect isMulti={isMulti} { ...rest }
            value={
                // match value to options value
                isMulti ? value && options.filter( opt => value.includes(opt.value) ) :
                options.find(opt => opt.value === value) || null
            }
            options={options}
            // return only value of selected option/options
            onChange={(option) => onChange(option && (isMulti ? option.map(opt => opt.value) : option.value))}
            isLoading={isLoading}
        />
    )
}

export default CountySelect