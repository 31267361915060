import React, { useEffect, useRef, useState } from 'react';
import { Badge, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SortableTable from "reactstrap-sortable-table";
import OwnersFilter from '../../components/filters/ownersFilter';
import OverlayLoader from '../../components/overlayLoader';
import Pages from '../../components/pages';
import { getPaginatedOwners } from '../../services/owners';
import { initColumns, usdFormat } from '../../utils/helper';
import { onQuerySuccess } from '../../utils/queryResponse';

const OwnersPage = () => {

    const [searchParams] = useSearchParams();
    const countyId = searchParams.get('countyId')
    const leaseId = searchParams.get('leaseId')
    const ownerId = searchParams.get('ownerId')
    const value = searchParams.get('value')

    const [owners, setOwners] = useState([])

    const [pagination, setPagination] = useState()
    const [pageNumber, setPageNumber] = useState(1)

    const [pageSize, setPageSize] = useState(10)
    const [filters, setFilters] = useState([countyId ?? '', leaseId ?? '', ownerId ?? '', value ?? ''])

    const [hasSearched, setHasSearched] = useState(false)
    const [showPageSizeOption, setShowPageSizeOption] = useState(value ? false : true)

    const [isLoading, setIsLoading] = useState(false)
    const isUnmounted = useRef(null)

    const navigate = useNavigate()

    useEffect(() => {
        isUnmounted.current = false

        window.onpopstate = () => {
            window.location.reload()
        }

        if (filters[0] || filters[1] || filters[2] || filters[3]) {
            fetchOwners(filters)
        }

        return () => {
            isUnmounted.current = true
        }
    }, [])


    const fetchOwners = async (filters = filters) => {
        setHasSearched(true)
        setIsLoading(true)

        navigate(`/owners?${filters[0] && 'countyId=' + filters[0]}${filters[1] && '&leaseId=' + filters[1]}${filters[2] && '&ownerId=' + filters[2]}${filters[3] && '&value=' + filters[3]}`)

        const data = await getPaginatedOwners(pageNumber, pageSize, ...filters)
        if (isUnmounted.current) return;

        onQuerySuccess(data, (result) => {
            console.log(result)
            setPagination(result)
            setOwners(result.data)
        })
        setIsLoading(false)
    }

    const changePage = async (pageNumber) => {
        setIsLoading(true)
        const data = await getPaginatedOwners(pageNumber, pageSize, ...filters)
        if (isUnmounted.current) return;

        onQuerySuccess(data, (result) => {
            setPagination(result)
            setOwners(result.data)
        })
        setIsLoading(false)
    }

    const handleSetPageSize = async (pageSize) => {
        setIsLoading(true)
        setPageSize(pageSize)
        const data = await getPaginatedOwners(pageNumber, pageSize, ...filters)
        if (isUnmounted.current) return;

        onQuerySuccess(data, (result) => {
            setPagination(result)
            setOwners(result.data)
        })
        setIsLoading(false)
    }

    return (
        <div className="position-relative">

            <Row>
                <Col>
                    <OwnersFilter onFilter={setFilters} filters={filters} fetch={fetchOwners} setShowPageSizeOption={setShowPageSizeOption} />
                </Col>
            </Row>

            {hasSearched &&
                <>
                    <Row className="mb-2">
                        <Col>
                            {showPageSizeOption &&
                                <div className="text-right d-flex justify-content-lg-end">
                                    <Form.Control as="select" className="w-auto d-inline mr-2" size="sm" onChange={(e) => handleSetPageSize(e.target.value)} defaultValue="10">
                                        <option value="" disabled>Select Page Size</option>
                                        <option value="2">2 per page</option>
                                        <option value="10">10 per page</option>
                                        <option value="20">20 per page</option>
                                        <option value="50">50 per page</option>
                                        <option value="100">100 per page</option>
                                        <option value="250">250 per page</option>
                                        <option value="500">500 per page</option>
                                    </Form.Control>
                                </div>
                            }
                        </Col>
                    </Row>

                    <Card>
                        <Card.Body>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        {owners.length > 0 ?
                                            <>
                                                <div className="print-wrapper">
                                                    <SortableTable
                                                        responsive
                                                        size="sm"
                                                        data={owners}
                                                        setData={setOwners}
                                                        columns={initColumns(owners, ['id', 'returnedEnvelope'])}
                                                        columnRender={[
                                                            { column: 'totalValue', render: (value) => usdFormat(value) },
                                                            { column: 'last_12MonthsProductionValue', render: (value) => usdFormat(value) },
                                                            {
                                                                column: 'name', render: (value, column, columnIndex, data, dataIndex) =>
                                                                    <Link to={`/owner/${owners[dataIndex].id}`}>{value}
                                                                    </Link>
                                                            },
                                                            {
                                                                column: 'number', render: (value, column, columnIndex, data, dataIndex) =>
                                                                    <Link to={`/owner/${owners[dataIndex].id}`}>{value}
                                                                    </Link>
                                                            },
                                                            {
                                                                column: 'properties', render: (value) => {
                                                                    return value.map(((x, index) => (
                                                                        <Badge className="me-1" key={`properties-details-${index}`}>{x}</Badge>
                                                                    )))
                                                                }
                                                            },

                                                        ]}
                                                        withTotalColumns={['last_12MonthsProductionValue']}
                                                        totalRender={(value) => <strong className="text-bold">{value.toFixed(2)}</strong>}
                                                        addProps={{ tBodyRow: { className: 'align-middle' } }}
                                                        dateColumns={['date']}
                                                    />
                                                </div>
                                                {showPageSizeOption &&
                                                    <Pages paginationData={pagination} changePage={changePage} />
                                                }
                                            </> :
                                            <>
                                                <hr />
                                                <span className="fst-italic">no owners found</span>
                                            </>
                                        }

                                        {(isLoading) &&
                                            <OverlayLoader />
                                        }
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </>
            }

        </div>
    );
};

export default OwnersPage;