import { toast } from "react-toastify"
import { getErrorMessages } from "./errorResponse"

export const onMutateSuccess = (callback, message) => (response, payload) => {
    const { result, statusCode, responseException } = response
    
    if(statusCode !== 200) {
        if(statusCode === 403) {
            toast.error("You are not allowed", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        }
        toast.error(getErrorMessages(responseException), {
            position: toast.POSITION.BOTTOM_RIGHT
        })
        return
    }
    callback(result, payload, statusCode, responseException)

    message && toast.success( message, {
        position: toast.POSITION.BOTTOM_RIGHT
    })
}

export const onQuerySuccess = ( data, callback ) => {
    if(data) {
        const { result, statusCode, responseException } = data
        if(statusCode === 200) {
           callback(result, statusCode, responseException)
        } else {
            toast.error(getErrorMessages(responseException), {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }
}