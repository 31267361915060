import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import NavigationBar from '../components/navigationBar';
import Sidebar from '../components/sidebar';
import { getCurrentUser } from '../services/auth';
import './dashboard.css';

const DashboardTemplate = () => {

    const [currentUser, setCurrentUser] = useState(getCurrentUser())
    const [sidebarToggle, setSidebarToggle] = useState(false)

    const _currentUser = getCurrentUser()
    console.log(_currentUser)

    return (

        _currentUser ? 
        
        <div className="container-fluid">
            <div className="row">

                <Sidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} currentUser={_currentUser} />

                <main role="main" className="pb-5 px-0 bg-lightgrey">

                    {/* {currentUser && */}
                    <NavigationBar setSidebarToggle={setSidebarToggle} currentUser={_currentUser} />
                    {/* } */}

                    <div className="content pt-3 px-4">

                        <Outlet />

                    </div>
                </main>
            </div>
        </div>
        : 
        <>
        {/* <h1>Hello World</h1> */}
        <Navigate to="/login" />
        </>
    )
}

export default DashboardTemplate;

