import React from 'react'
import { useElementLoader } from '../utils/loader'

const OverlayLoader = ({ noTextDisplay }) => {
    const loader = useElementLoader(30)
    return (
        <div className='d-flex justify-content-center align-items-center bg-white' 
            style={{
                position:'absolute',
                top:'0px',
                left:'0px',
                width:'100%',
                height:'100%',
                background: '#fff',
                opacity:0.9
            }}
        >
            {loader} {noTextDisplay ? '' : 'loading...'}
        </div>
    )
}

export default OverlayLoader