import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import SortableTable from 'reactstrap-sortable-table';
import OverlayLoader from '../../components/overlayLoader';
import UploadFiles from '../../components/uploadFiles';
import { getPrices } from '../../services/prices';
import { uploadMonthlyOilPrice } from '../../services/uploads';
import { initColumns, toLocalDateString } from '../../utils/helper';
import { useElementLoader } from '../../utils/loader';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import PriceSetModal from './priceSet';

const MonthlyOilPricePage = () => {

    const [priceSetModal, setPriceSetModal] = useState(false)

    const [docsToUpload, setDocsToUpload] = useState([])
    const [existingDocs, setExistingDocs] = useState([])
    const [selectedFile, setSelectedFile] = useState()

    const [prices, setPrices] = useState([])
    const [groupedPrices, setGroupedPrices] = useState([])

    const [fileLoader] = useElementLoader()
    const [fileLoaderMap, setFileLoaderMap] = useState({})

    const { data, isFetching } = useQuery('prices', () => getPrices())

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            console.log(result)

            const groupedPrices = groupByYear(result)
            console.log(groupedPrices)

            setPrices(result)
            setGroupedPrices(groupedPrices)
        })
    }, [data])

    const groupByYear = (arr) => {

        let groupedPrices = []
        let currentYear = null

        arr.forEach((x) => {
            const year = new Date(x['date']).getFullYear()
            if (year === currentYear) {
                return
            } else {
                currentYear = year
            }

            const obj = {
                year: year,
                prices: arr.filter(y => new Date(y.date).getFullYear() === year)
            }

            groupedPrices.push(obj)
        })

        return groupedPrices


    }

    const uploadDocs = useMutation(uploadMonthlyOilPrice, {
        onSuccess: onMutateSuccess((result) => {
            //const newDocs = [...existingDocs, ...result.files]
            //setExistingDocs(newDocs)
            //queryClient.invalidateQueries('project')
        }, ' Upload Successful ')
    })

    const handleUploadDocs = () => {
        uploadDocs.mutate({ files: docsToUpload.map(x => x.file) })
        setDocsToUpload([])
    }

    return (
        <>
            <PriceSetModal display={priceSetModal} toggle={setPriceSetModal} />

            <h5>Monthly Oil Price</h5>
            <hr />
            <div className="mb-4">
                <p><strong>Upload Documents</strong></p>
                <div className="mb-4">
                    <UploadFiles filesToUpload={docsToUpload} setFilesToUpload={setDocsToUpload} label='Select Documents' fileExtensions={['.xlsx', '.xls']} />
                    <Button size='sm' className="ms-2" disabled={!docsToUpload.length || uploadDocs.isLoading}
                        onClick={handleUploadDocs}
                    >
                        {uploadDocs.isLoading ? 'Uploading' : 'Upload'}
                    </Button>
                </div>
            </div>

            <div className="mb-3">
                <Button onClick={() => setPriceSetModal(true)}>Add New Price</Button>
            </div>

            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <div>
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th>Year</th>
                                            <th>Jan</th>
                                            <th>Feb</th>
                                            <th>Mar</th>
                                            <th>Apr</th>
                                            <th>May</th>
                                            <th>Jun</th>
                                            <th>Jul</th>
                                            <th>Aug</th>
                                            <th>Sep</th>
                                            <th>Oct</th>
                                            <th>Nov</th>
                                            <th>Dec</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {groupedPrices.map((x, index) => (
                                            <tr key={`year-price-${index}`}>
                                                <th>{x.year}</th>

                                                {x.prices.map((y, index) => (
                                                    <td key={`price-${index}`}>{y.price}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            {(isFetching) &&
                                <div style={{ height: '100px' }}>
                                    <OverlayLoader />
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </>
    );
};

export default MonthlyOilPricePage;