import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo_hor.png';

const ThankYouPage = () => {
    return (
        <>
            <section style={{ height: '100vh', backgroundColor: '#dedede' }}>
                <div className="h-100">
                    <Row className="justify-content-center h-100 g-0">
                        <Col md="6" lg="4" className="h-100">
                            <div className="login-container d-flex align-items-center h-100">

                                <div className="w-100">

                                    <div className="w-100 text-center mb-3">
                                        <img style={{ maxWidth: '200px' }} src={logo} alt="dn saas logo" />
                                    </div>

                                    <Card>
                                        <Card.Body className="text-center">
                                            
                                            <h2>Thank you for registering at Devnify</h2>
                                            <p className="mb-0">We have sent a verification code to your email.</p> 
                                            <p>Please click <Link className="text-bold" to="/login">here</Link> to login.</p>

                                        </Card.Body>
                                    </Card>

                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default ThankYouPage;