import http from "./http"
import { getToken } from './auth'
import { format } from 'date-fns'

const base = 'Productions'

export const getPaginatedProductions = async (pageNumber, pageSize, countyId, operatorId, leaseId, view, startDate, endDate, searchText) => {

    const params = new URLSearchParams()
    params.append('pageNumber', pageNumber)
    params.append('pageSize', pageSize)
    // countyId && params.append(countyId.includes(',') ? 'countyIdMulti' : 'countyId', countyPayload)
    //operatorId && params.append('operatorId', operatorId)
    //leaseId && params.append('leaseId', leaseId)
    view && params.append('view', view)
    startDate && params.append('startDate', format(startDate, 'MM/dd/yyyy'))
    endDate && params.append('endDate', format(endDate, 'MM/dd/yyyy'))
    searchText && params.append('searchText', searchText)


    if (countyId) {
        const countyIds = countyId instanceof Array ? countyId : countyId.split(',')
        countyIds.forEach(element => {
            params.append('countyIdMulti', element)
        });
    }

    if (operatorId) {
        const operatorIds = operatorId instanceof Array ? operatorId : operatorId.split(',')
        operatorIds.forEach(element => {
            params.append('operatorIdMulti', element)
        });
    }

    if (leaseId) {
        const leaseIds = leaseId instanceof Array ? leaseId : leaseId.split(',')
        leaseIds.forEach(element => {
            params.append('leaseIdMulti', element)
        });
    }

    const { data } = await http.get(`${base}/`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export const getOperatorById = async (operatorId, countyId, startDate, endDate) => {

    const params = new URLSearchParams()

    // startDate && params.append('startDate', startDate)
    // endDate && params.append('endDate', endDate)
    countyId && params.append('countyId', countyId)

    const { data } = await http.get(`${base}/operator/${operatorId}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        },
        params
    })
    return data
}

export const getLeaseById = async (leaseId, startDate, endDate) => {

    const params = new URLSearchParams()

    // startDate && params.append('startDate', format(startDate, 'MM/dd/yyyy'))
    // endDate && params.append('endDate', format(endDate, 'MM/dd/yyyy'))

    const { data } = await http.get(`${base}/lease/${leaseId}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        },
        params
    })
    return data
}