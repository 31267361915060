import React from 'react';
import Table from 'reactstrap-sortable-table';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { getSystemIssues, deleteSystemIssues } from '../../services/systemIssues';
import { toast } from 'react-toastify';
import { getErrorMessages } from '../../utils/errorResponse';
import OverlayLoader from '../../components/overlayLoader';
import { format } from 'date-fns'

const columns = ['id', 'error', 'type', 'user', 'date']

const SystemIssuesPage = () => {

    const queryClient = useQueryClient()
    const systemIssuesQuery = useQuery('systemIssues', getSystemIssues)

    const systemIssuesDelete = useMutation( deleteSystemIssues, {
        
        onSuccess: (response, payload) => {
            const { statusCode, responseException } = response
            
            if(statusCode !== 200) {
                toast.error(getErrorMessages(responseException), {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
                return
            }
            //replace old query data with new data
            queryClient.setQueryData('systemIssues', oldData => {
                const newData = {...oldData}
                // result prop is the array of users that is fetch from server
                newData.result = oldData.result.filter(issue => issue.id !== payload.id)
                return newData
            })
            toast.success( ' Successfully deleted a system issue ', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        },
        onError: ( {response} ) => {
            toast.error( getErrorMessages(response.data.responseException) , {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    })
    const systemIssues = systemIssuesQuery.data?.result ?? []
    console.log(systemIssues)

    return (
        <Row>
            <Col>
                <h4>System Issues</h4>
                <Card>
                    <Card.Body>
                        <div style={{position: 'relative'}}>
                            <Table
                                style={{fontSize:'0.75rem'}}
                                responsive
                                data={systemIssues}
                                columns={columns}
                                isSortable={false}
                                lastColumnRender={ (issue) => {
                                    const { id, type } = issue
                                    return <Button size="sm" onClick={() => systemIssuesDelete.mutate({id, type})}>Delete</Button>
                                }}
                                columnRender={[{
                                    column: 'date',
                                    render: (value) => format(new Date(value), 'MM/dd/yyyy hh:mm:ss')
                                }]}
                            />
                            { systemIssues.length < 1 &&
                            <p className='fst-italic px-2'>No data</p>
                            }

                            { systemIssuesQuery.isLoading &&
                            <OverlayLoader  />
                            }
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default SystemIssuesPage