import http from './http'
import { getToken } from './auth'

const base = 'SystemIssues'

export async function getSystemIssues() {

    const { data } = await http.get(`${base}/`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function deleteSystemIssues(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }
    const { id, type } = payload
    const { data } = await http.delete(`${base}/${id}/${type}`, config)
    return data
}
