import React, { useEffect, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import OverlayLoader from '../../components/overlayLoader';
import { getOperatorById } from '../../services/productions';
import { onQuerySuccess } from '../../utils/queryResponse';
import ProductionsTab from './productionsTab';

const OperatorPage = (props) => {

    let { id } = useParams();
    const params = new URLSearchParams(window.location.search)
    
    const county = params.get('county')
    const countyId = params.get('countyId')
    const operatorName = params.get('operatorName')
    const operatorNumber = params.get('operatorNumber')
    const startDate = params.get('startDate')
    const endDate = params.get('endDate')

    const [operator, setOperator] = useState()
    const { data, isFetching } = useQuery(['operator', id], () => getOperatorById(id, countyId, startDate, endDate))

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            console.log(result)
            setOperator(result)
        })
    }, [data])

    return (
        <div>

            <Card className="mb-3">
                <Card.Body>
                    <p className="mb-0"><span className="text-bold">County</span>: {county}</p>
                    <p className="mb-0"><span className="text-bold">Operator Name</span>: {operatorName}</p>
                    <p className="mb-0"><span className="text-bold">Operator Number</span>: {operatorNumber}</p>
                </Card.Body>
            </Card>

            <Card>
                <Card.Body>
                    {operator ?
                        <Tabs defaultActiveKey={"monthly"} className="mb-3">
                            <Tab eventKey="monthly" title="Monthly" mountOnEnter>
                                <ProductionsTab tabProductions={operator?.perMonth} />
                            </Tab>
                            <Tab eventKey="yearly" title="Yearly" mountOnEnter>
                                <ProductionsTab tabProductions={operator?.perYear} />
                            </Tab>
                            <Tab eventKey="perlease" title="Per Lease" mountOnEnter>
                                <ProductionsTab tabProductions={operator?.perLease} />
                            </Tab>
                        </Tabs>
                        :
                        <div style={{height:'100px'}}>
                            <OverlayLoader />
                        </div>
                    }
                </Card.Body>
            </Card>
        </div>
    );
};

export default OperatorPage;