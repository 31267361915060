import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getLeasesByIds, searchLeases } from '../../services/leases';
import { debounce } from '../../utils/helper';
import { onQuerySuccess } from '../../utils/queryResponse';
import FormSelect from '../forms/formSelect';

const LeaseSelect = ({ value, onChange, isMulti, setLeaseId, ...rest }) => {

    const [searchParams] = useSearchParams();
    const leasesIds = searchParams.get('leaseId')

    const [options, setOptions] = useState([])
    const [inputText, setInputText] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const isSelecting = useRef()
    const isUnmounted = useRef()

    useEffect(() => {
        isUnmounted.current = false
        leasesIds && fetchLeasesByIds(leasesIds)
        return () => {
            isUnmounted.current = true
        }
    }, [])

    useEffect(() => {
        isSelecting.current = false
    }, [])

    useEffect(() => {
        fetchSearch(inputText)
    }, [inputText])

    const fetchLeasesByIds = async (leasesIds) => {
        const data = await getLeasesByIds(leasesIds)
        onQuerySuccess(data, (result) => {

            console.log(result)
            setOptions(result.map(x => {
                return {
                    value: x.id,
                    label: `${x.leaseName}, #${x.leaseNumber}`
                }
            }))
            setLeaseId(result.map(x => x.id))
        })
    }

    const fetchSearch = useMemo(
        () =>
            debounce(async (text) => {

                if (text === "") {
                    //TODO: isSelecting.current && setOptions([])
                    return
                }

                setIsLoading(true)
                const { result, statusCode } = await searchLeases(text)
                if (isUnmounted.current) return;

                if (statusCode === 200) {
                    setOptions(result.map(d => ({
                        value: d.id, label: d.lease
                    })))
                }
                setIsLoading(false)
            }),
        []
    )

    const handleInputChange = (text) => {
        setInputText(text)
    }

    const handleOnCloseMenu = () => {
        if (isSelecting.current) {
            setInputText('')
            isSelecting.current = false
        } else {
            setInputText(inputText)
        }
    }

    const handleSearchSelect = (option) => {
        console.log(option)
        let leaseIds = []
        option.forEach(x => {
            leaseIds.push(x.value)
        });
        setLeaseId(leaseIds)
        setInputText(option?.label)
        isSelecting.current = true
    }

    return (
        <FormSelect isMulti={isMulti} {...rest}
            value={
                // match value to options value
                isMulti ? value && options.filter(opt => value.includes(opt.value)) :
                    options.find(opt => opt.value === value) || null
            }
            inputValue={inputText}
            options={options}
            // return only value of selected option/options
            onChange={handleSearchSelect}
            onInputChange={handleInputChange}
            onMenuClose={handleOnCloseMenu}
            isLoading={isLoading}
            blurInputOnSelect
            isSeachable
            isClearable
        />
    )
}

export default LeaseSelect