import http from './http'
import { getToken } from './auth'

const base = 'Counties'

export async function getCounties() {

    const { data } = await http.get(`${base}/`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}