import { useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import CountySelect from '../selects/countySelect';
import LeaseSelect from '../selects/leaseSelect';
import OwnerSelect from '../selects/ownerSelect';

const OwnersFilter = ({ isFiltering, onFilter, filters, fetch, setShowPageSizeOption }) => {

    const [countyId, setCountyId] = useState(filters[0] ?? '')
    const [leaseId, setLeaseId] = useState(filters[1])
    const [ownerId, setOwnerId] = useState(filters[2])
    const [value, setValue] = useState(filters[3])

    const handleFilter = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const _filters = [
            countyId ?? '',
            leaseId ?? '',
            ownerId ?? '',
            value ?? '',
        ]

        setShowPageSizeOption(value ? false : true)

        onFilter(_filters)
        fetch(_filters)
    }

    const handleReset = () => {
        onFilter(['', '', ''])
        setCountyId('')
        setLeaseId('')
        setOwnerId('')
        setValue('')
    }

    // const handleValueFilterChange = (e) => {
    //     const value = e.target.value
    //     if(value) {
    //         setShowPageSizeOption(false)
    //         setValue(value)
    //     } else {
    //         setShowPageSizeOption(true)
    //     }
    // }

    return (
        <div className="mb-3">

            <Form onSubmit={handleFilter}>
                <Card className='mt-2'>
                    <Card.Body>

                        <Row className="mb-2">
                            <Col>
                                <Row>
                                    <Col lg="2">
                                        <CountySelect className="mb-2" label='County' placeholder='County' isClearable
                                            value={countyId} onChange={setCountyId} isMulti
                                        />
                                    </Col>
                                    <Col lg="3">
                                        {/* <FormInput label="Owner" value={owner} onChange={(e) => setOwner(e.target.value)} /> */}
                                        <OwnerSelect className="mb-2" label='Owner' placeholder='Search Owner'
                                            value={ownerId} setOwnerId={setOwnerId} isMulti
                                        />
                                    </Col>
                                    <Col lg="3">
                                        <LeaseSelect className="mb-2" label='Lease' placeholder='Search Lease'
                                            value={leaseId} setLeaseId={setLeaseId} isMulti
                                        />
                                    </Col>
                                    <Col lg="2">
                                        <Form.Label>Value</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text className="bg-transparent">$</InputGroup.Text>
                                            <Form.Control value={value} type="number" step=".01" onChange={(e) => setValue(e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label>&nbsp;</Form.Label>
                                        <Form.Group>
                                            <Button type='submit' disabled={isFiltering} >{isFiltering ? 'Searching...' : "Search"}</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </Form>

        </div>

    )
}

export default OwnersFilter