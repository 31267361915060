//import jwtDecode from 'jwt-decode';
import React, { useState } from 'react';
import { Button, Card, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo_hor.png';
import { login } from '../services/auth';
import { getErrorMessages } from '../utils/errorResponse';
import { useElementLoader } from '../utils/loader';

const LoginPage = () => {

    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [error, setError] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [loader] = useElementLoader()

    let navigate = useNavigate();

    const handleLogin = async () => {

        setError()
        setIsLoading(true)

        let account = {
            username,
            password
        }

        console.log(account)

        try {
            const { data: { result, statusCode, responseException } } = await login(account)

            console.log(result)

            if (statusCode !== 200) {
                console.log(responseException)
                setError(getErrorMessages(responseException))
            } else {
                localStorage.setItem('dn-saas-token', result.token)
                navigate('/home')
            }
        } catch (e) {
            //TODO: display error
            console.log(e)
            //setError(e.message)
        }

        setIsLoading(false)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin()
        }
    }

    return (
        <>
            <section style={{ height: '100vh', backgroundColor: '#dedede' }}>
                <div className="h-100">
                    <Row className="justify-content-center h-100 g-0">
                        <Col md="6" lg="4" className="h-100">
                            <div className="login-container d-flex align-items-center h-100">

                                <div className="w-100">

                                    <div className="w-100 text-center mb-3">
                                        <img style={{ maxWidth: '200px' }} src={logo} alt="dn saas logo" />
                                    </div>

                                    <Card>
                                        <Card.Body>
                                            <p className="text-center">Please log in to start</p>
                                            <InputGroup className="mb-2">
                                                <FormControl className="border-right-0" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                                                <InputGroup.Text><i className="fa-solid fa-user"></i></InputGroup.Text>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                                <FormControl onKeyDown={handleKeyDown} className="border-right-0" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                                <InputGroup.Text><i className="fa-solid fa-lock"></i></InputGroup.Text>
                                            </InputGroup>

                                            <Button disabled={isLoading} className="w-100 mt-2" onClick={() => handleLogin()}>{isLoading ? loader : 'Login'}</Button>

                                            {error &&
                                                <p className="mt-3 mb-0 text-center text-danger"><small>{error}</small></p>
                                            }
                                        </Card.Body>
                                    </Card>

                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default LoginPage;