import React from 'react';
import { Button, Card, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import FormInput from '../components/forms/formInput';
import { toast } from 'react-toastify';
import { getErrorMessages } from '../utils/errorResponse';
import { useEffect } from 'react';
import { useState } from 'react';
import { createUser } from '../services/users';
import { industries } from '../utils/industries';
import FormSelect from '../components/forms/formSelect';

const RegisterPage = () => {

    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        email: "",
        firstCompanyName: "",
        firstCompanyIndustry: ""
    })
    const [confirmPassword, setConfirmPassword] = useState("")

    const [error, setError] = useState()

    const navigate = useNavigate()

    useEffect(() => {

    }, [])

    const queryClient = useQueryClient()
    const usersMutation = useMutation(createUser, {
        onSuccess: (response) => {
            const { statusCode, responseException } = response

            if (statusCode !== 200) {
                toast.error(getErrorMessages(responseException), {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
                return
            }

            queryClient.invalidateQueries('users')
            toast.success(` Successfully created a user. You can now use your account to login.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })

            navigate('/thank-you')

        },
        onError: ({ response }) => {
            toast.error(getErrorMessages(response.data.responseException), {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    })

    const handleChangeInput = (prop) => {
        return (e) => {
            const value = e.target.value
            setUser({ ...user, [prop]: value })
        }
    }

    const handleSubmit = async (e) => {

        setError()
        e.preventDefault()
        e.stopPropagation()

        if (user.password !== confirmPassword) {
            setError("Passwords do not match!")
            return
        }

        let payload = { ...user }
        payload.firstCompanyIndustry = payload.firstCompanyIndustry.value
        console.log(payload)

        usersMutation.mutate(payload)
    }

    return (
        <>
            <section style={{ height: '100vh', backgroundColor: '#dedede' }}>
                <div className="h-100">
                    <Row className="justify-content-center h-100 g-0">
                        <Col lg="6" className="h-100">
                            <div className="login-container d-flex align-items-center h-100">

                                <div className="w-100">

                                    <div className="w-100 text-center mb-3">
                                        <h5>Registration Form</h5>
                                    </div>

                                    <Card>
                                        <Card.Body>
                                            <Form onSubmit={handleSubmit}>
                                                <p className="mb-2 text-bold">Your account details</p>
                                                <hr className="mt-0" />
                                                <Row className="mb-3">
                                                    <Col lg="6">
                                                        <FormInput required label="Username" onChange={handleChangeInput('username')} />
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormInput required type="email" label="Email" onChange={handleChangeInput('email')} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col lg="6">
                                                        <FormInput required type="password" label="Password" onChange={handleChangeInput('password')} />
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormInput required type="password" label="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <p className="mb-2 text-bold">Tell us about yourself</p>
                                                <hr className="mt-0" />
                                                <Row className="mb-3">
                                                    <Col lg="6">
                                                        <FormInput required label="First Name" onChange={handleChangeInput('firstName')} />
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormInput required label="Last Name" onChange={handleChangeInput('lastName')} />
                                                    </Col>
                                                </Row>
                                                <p className="mb-2 text-bold">Tell us about your company</p>
                                                <hr className="mt-0" />
                                                <Row className="mb-3">
                                                    <Col lg="6">
                                                        <FormInput required label="Company Name" onChange={handleChangeInput('firstCompanyName')} />
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormSelect
                                                            label={"Company Industry"}
                                                            value={user.firstCompanyIndustry}
                                                            options={industries.map((industry) => {
                                                                return {value:industry.name, label:industry.name}
                                                            })}
                                                            onChange={(option) => setUser({ ...user, firstCompanyIndustry: option })}
                                                        />
                                                    </Col>
                                                </Row>

                                                <div className="mt-4 text-center">
                                                    <Button type="submit" disabled={usersMutation.isLoading} className="px-4">{usersMutation.isLoading ? 'Submitting...' : 'Submit'}</Button>
                                                </div>

                                                <p className="mb-1 mt-2 text-center">
                                                    Already have an account? login <Link to="/login"><strong>here</strong></Link>.
                                                </p>

                                                {error &&
                                                    <p className="mt-3 mb-0 text-center text-danger"><small>{error}</small></p>
                                                }
                                            </Form>
                                        </Card.Body>
                                    </Card>

                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
};

export default RegisterPage;