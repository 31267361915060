import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { logout } from '../services/auth';
import logo from '../assets/images/logo_hor.png';
import { NavLink } from 'react-router-dom';

const NavigationBar = ({ setSidebarToggle, currentUser }) => {

    const [scrollOffset, setScrollOffset] = useState(0);

    const handleLogout = () => {
        logout()
        window.location.reload();
    }

    useEffect(() => {
        const onScroll = () => setScrollOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <nav className={`navbar navbar-light bg-white sticky-top flex-md-nowrap justify-content-between p-0 ${scrollOffset > 0 && 'bg-white'}`}>

            <Button className="ms-3 d-md-none d-inline" onClick={() => setSidebarToggle(true)}><i className="fas fa-bars"></i></Button>

            <div className="h-100 px-3">
                <NavLink reloadDocument to="home"><img className="logo-img h-100" src={logo} alt="sags logo" /></NavLink>
            </div>

            <ul className="navbar-nav px-3 d-none d-md-flex">
                <li className="px-2">
                    <span className="cursor-pointer" onClick={() => handleLogout()}><i className="fa-solid fa-power-off"></i></span>
                </li>

                <li className="px-2 d-flex align-items-center">
                    <Link to='/profile' className='cursor-pointer'>
                        <span>{currentUser?.FirstName} {currentUser?.LastName}</span>
                    </Link>
                </li>

                <li className="px-2">
                    <Dropdown className="no-caret">

                        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                            <i className="fa-solid fa-bell"></i>

                            <sup><span className="badge bg-danger">0</span></sup>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item as="button">
                                <a href={`/calendar`}><small><strong>#</strong> past reminders</small></a>
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                                <a href={`/calendar`}><small><strong>#</strong> reminders today</small></a>
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                                <a href={`/calendar`}><small><strong>#</strong> reminders tomorrow</small></a>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>

                <li className="pe-3">
                    <NavLink to='/uploads/cad'>CAD</NavLink>
                </li>
                <li className="pe-3">
                    <NavLink to='/uploads/prices'>Prices</NavLink>
                </li>
                <li className="pe-3">
                    <NavLink to='/owners' href='/owners'>Owners</NavLink>
                </li>
                <li className="pe-3">
                    <NavLink reloadDocument to='/home'>Production</NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default NavigationBar;