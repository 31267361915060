import axios from 'axios';
import { logout } from './auth';

axios.defaults.baseURL = "https://api.treyroberson.com/v1/"

axios.interceptors.response.use(
    null,
    error => {
        const expectedError =
            error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500

        if(error.response.status === 401) {
            logout()
            
            if (window.location.pathname.indexOf('/login') !== 0)
                window.location.assign("/login")
        }

        if (expectedError) return Promise.resolve(error.response)

        return Promise.reject(error.response)
    }
)

const CancelToken = axios.CancelToken;
export const cancelTokenSource = CancelToken.source();

const http = {
    get: axios.get,
    put: axios.put,
    post: axios.post,
    delete: axios.delete,
    patch: axios.patch
    //TODO: setJWT
}

export default http;