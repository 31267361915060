import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from 'react-dom/client';
import {
  QueryClient,
  QueryClientProvider
} from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './assets/css/responsive.css';
import './assets/css/style.css';
import './assets/css/vstyle.css';
import './index.css';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24,
      onError: () =>
        toast.error(`Something went wrong: No response from server.`, {
          position: toast.POSITION.BOTTOM_RIGHT
      }),
    },
    mutations: {
      onError: () =>
        toast.error(`Something went wrong: No response from server.`, {
          position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
