import http from "./http"
import { getToken } from './auth'
import { format } from 'date-fns'

const base = 'Owners'

export const getPaginatedOwners = async (pageNumber, pageSize, countyId, leaseId, ownerId, value) => {

    const params = new URLSearchParams()
    params.append('pageNumber', pageNumber)
    params.append('pageSize', pageSize)

    // countyId && params.append('countyId', countyId)
    //leaseId && params.append('leaseId', leaseId)
    ownerId && params.append('ownerId', ownerId)
    value && params.append('value', value)

    if (countyId) {
        const countyIds = countyId instanceof Array ? countyId : countyId.split(',')
        countyIds.forEach(element => {
            params.append('countyIdMulti', element)
        });
    }

    if (ownerId) {
        const ownerIds = ownerId instanceof Array ? ownerId : ownerId.split(',')
        ownerIds.forEach(element => {
            params.append('ownerIdMulti', element)
        });
    }

    if (leaseId) {
        const leaseIds = leaseId instanceof Array ? leaseId : leaseId.split(',')
        leaseIds.forEach(element => {
            params.append('leaseIdMulti', element)
        });
    }

    const { data } = await http.get(`${base}/`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export async function getOwnerById(id) {

    const { data } = await http.get(`${base}/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        }
    })
    return data
}

export async function getOwnersByIds(ids) {

    const params = new URLSearchParams()

    console.log(ids)
    if (ids) {
        const _ids = ids instanceof Array ? ids : ids.split(',')
        console.log(_ids)
        _ids.forEach(element => {
            params.append('ids', element)
        });
    }

    const { data } = await http.get(`${base}/get-multi`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        },
        params
    })
    return data
}

export async function searchOwners(searchText) {

    const { data } = await http.get(`${base}/search/${searchText}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function updateOwner(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/', payload, config)
    return data
}
