import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import OverlayLoader from '../../components/overlayLoader';
import { getOwnerById } from '../../services/owners';
import { onQuerySuccess } from '../../utils/queryResponse';
import SortableTable from "reactstrap-sortable-table";
import { initColumns, usdFormat } from '../../utils/helper';
import { Link } from 'react-router-dom';
import OwnerSetModal from './ownerSet';

const OwnerPage = () => {

    let { id } = useParams();

    const [editModal, setEditModal] = useState(false)

    const [owner, setOwner] = useState()
    const [ownerLeases, setOwnerLeases] = useState([])

    const ownerQuery = useQuery(['owner', id], () => getOwnerById(id), {
        staleTime: 1000 * 60 * 2, // 2 minutes
        refetchOnMount: true,
    })

    const { data, isFetching } = ownerQuery
    useEffect(() => {
        onQuerySuccess(data, (result) => {
            console.log(result)
            setOwner(result)
            setOwnerLeases(result.leases)
        })
    }, [data])

    return (
        <>
            <OwnerSetModal display={editModal} toggle={setEditModal} owner={owner} setOwner={setOwner} />

            <div className="position-relative">

                <Card className="mb-3">

                    <Card.Body>

                        <div style={{ position: 'relative', minHeight: '100px' }}>

                            {!isFetching &&
                                <>
                                    <div className="mb-2">
                                        <Row>
                                            <Col lg="5">
                                                <p className="mb-0"><span className="text-bold">Owner Name</span>: {owner?.name}</p>
                                                <p className="mb-0"><span className="text-bold">Number</span>: {owner?.number}</p>
                                                <p className="mb-0"><span className="text-bold">Address</span>: {owner?.address}</p>
                                                <p className="mb-0"><span className="text-bold">Phone No.</span>: {owner?.phone}</p>
                                                <p className="mb-0"><span className="text-bold">Email</span>: {owner?.email}</p>
                                                <p className="mb-0"><span className="text-bold">Attn.</span>: {owner?.attn}</p>
                                                {/* <span className="text-bold">Returned Envelope</span>: <Form.Check inline className="no-pointer-events" checked={owner?.returnedEnvelope} onChange={() => { }} /> */}
                                                <span className="text-bold">Returned Envelope</span>: {owner?.returnedEnvelope ? <i className="fa-solid fa-square-check"></i> : <i className="fa-regular fa-square"></i>}
                                                
                                            </Col>
                                            <Col>
                                                <p className="mb-0"><span className="text-bold">Total Value</span>: {usdFormat(owner?.totalValue)}</p>
                                                {owner?.note &&
                                                    <p className="mb-0"><span className="text-bold">Note</span>: {owner?.note}</p>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                    <Button size="sm" onClick={() => setEditModal(true)}>Edit</Button>
                                </>
                            }

                            {(isFetching) &&
                                <OverlayLoader />
                            }

                        </div>

                    </Card.Body>
                </Card>

                <Card>
                    <Card.Body>

                        <Row>
                            <Col>
                                <div style={{ position: 'relative', minHeight: '100px' }}>
                                    {ownerLeases.length > 0 ?
                                        <>
                                            <div className="print-wrapper">
                                                <SortableTable
                                                    responsive
                                                    size="sm"
                                                    data={ownerLeases}
                                                    setData={setOwnerLeases}
                                                    columns={initColumns(ownerLeases, ['county', 'leaseId'])}
                                                    columnRender={[
                                                        {
                                                            column: 'leaseId', render: (value, column, columnIndex, data, dataIndex) =>
                                                                <Link to={`/productions/lease/${value}?county=${ownerLeases[dataIndex].county}&leaseName=${ownerLeases[dataIndex].leaseName}&leaseNumber=${ownerLeases[dataIndex].leaseNumber}`}>{value}</Link>
                                                        },
                                                        {
                                                            column: 'leaseName', render: (value, column, columnIndex, data, dataIndex) =>
                                                                <Link to={`/productions/lease/${ownerLeases[dataIndex].leaseId}?county=${ownerLeases[dataIndex].county}&leaseName=${ownerLeases[dataIndex].leaseName}&leaseNumber=${ownerLeases[dataIndex].leaseNumber}`}
                                                                >{value}
                                                                </Link>
                                                        },
                                                        {
                                                            column: 'leaseNumber', render: (value, column, columnIndex, data, dataIndex) =>
                                                                <Link to={`/productions/lease/${ownerLeases[dataIndex].leaseId}?county=${ownerLeases[dataIndex].county}&leaseName=${ownerLeases[dataIndex].leaseName}&leaseNumber=${ownerLeases[dataIndex].leaseNumber}`}
                                                                >{value}
                                                                </Link>
                                                        },
                                                        { column: 'averageMonthlyRevenueOverLast_12Months', render: (value) => usdFormat(value) },
                                                        { column: 'totalValue', render: (value) => usdFormat(value) }
                                                    ]}
                                                />
                                            </div>
                                        </> :
                                        <>
                                            <hr />
                                            <span className="fst-italic">no data found</span>
                                        </>
                                    }

                                    {(isFetching) &&
                                        <OverlayLoader />
                                    }
                                </div>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>

            </div>
        </>
    );
};

export default OwnerPage;