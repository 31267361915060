import React, { useRef } from 'react'
import { Button } from 'react-bootstrap'
import OverlayLoader from './overlayLoader'

const UploadFiles = ({ filesToUpload, setFilesToUpload, imageOnly = false, fileExtensions = [], isMulti = true, label = 'Upload Files', isLoading }) => {

    const _fileExtensions = fileExtensions.length > 0 ? fileExtensions :
        ['image/*', '.txt', '.doc', '.docx', 'application/msword', 'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            '.xlsx', '.xls', '.csv', '.xlm', '.xml', 'ods', '.odt', '.ppt', '.pptx', '.log',
            '.msg', 'pages', '.tex', 'wpd', '.wps', '.rar', '.zip', '.gz', '.7z']

    const inputFileRef = useRef(null)

    const loadFiles = (e) => {

        let docFiles = [...filesToUpload]
        const files = e.target.files
        Array.from(files).forEach((file) => {
            if (isMulti)
                docFiles.push({
                    'file': file,
                    'url': URL.createObjectURL(new Blob([file], { type: "application/zip" })),
                    'name': file.name,
                    'type': file.type
                })
            else
                docFiles = [{
                    'file': file,
                    'url': URL.createObjectURL(new Blob([file], { type: "application/zip" })),
                    'name': file.name,
                    'type': file.type
                }]
        })

        setFilesToUpload(docFiles)
    }

    const removeFile = (index) => {
        setFilesToUpload(filesToUpload.filter((file, _index) => index !== _index))
    }

    return (
        <>
            <input multiple={isMulti} className="d-none" type="file" ref={inputFileRef}
                accept={imageOnly ? 'image/*' : _fileExtensions.join()}
                name="image" onChange={(e) => loadFiles(e)} />
            {filesToUpload &&
                <div style={{ position: 'relative' }}>
                    <div className="files d-flex flex-wrap">
                        {filesToUpload.map((file, index) => (
                            <React.Fragment key={`file-${index}`}>
                                {!file.type.includes('image/') ?
                                    <div className="file-wrapper not-image-file me-3 mb-3 center-xy bg-lightblue p-3">
                                        <div><span>{file.name}</span></div>
                                        <i className="file-btn fas fa-trash-alt" onClick={() => removeFile(index)} title="remove"></i>
                                    </div>
                                    :
                                    <div className="file-wrapper image-file me-3 mb-3 bg-lightblue">
                                        <img src={file.url} alt="file preview" className="h-100" />
                                        <i className="file-btn fas fa-trash-alt" onClick={() => removeFile(index)} title="remove"></i>
                                    </div>
                                }
                            </React.Fragment>
                        ))}
                    </div>
                    {isLoading &&
                        <OverlayLoader />
                    }
                </div>
            }
            <Button size="sm" variant="outline-secondary" onClick={() => inputFileRef.current.click()}>{label}</Button>
        </>
    )
}

export default UploadFiles