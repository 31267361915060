import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import CountySelect from '../../components/selects/countySelect';
import UploadFiles from '../../components/uploadFiles';
import { getCadUploads } from '../../services/cadUploads';
import { uploadCAD, uploadMonthlyOilPrice } from '../../services/uploads';
import { useElementLoader } from '../../utils/loader';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import SortableTable from 'reactstrap-sortable-table';
import OverlayLoader from '../../components/overlayLoader';
import { initColumns, toLocalDateString } from '../../utils/helper';

const CADPage = () => {

    const [docsToUpload, setDocsToUpload] = useState([])
    const [existingDocs, setExistingDocs] = useState([])
    const [selectedFile, setSelectedFile] = useState()

    const [countyId, setCountyId] = useState('')
    const [cadUploads, setCadUploads] = useState([])

    const [fileLoader] = useElementLoader()
    const [fileLoaderMap, setFileLoaderMap] = useState({})

    const { data, isFetching } = useQuery('cadUploads', () => getCadUploads())

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            console.log(result)
            setCadUploads(result)
        })
    }, [data])

    const uploadDocs = useMutation(uploadCAD, {
        onSuccess: onMutateSuccess((result) => {
            console.log(result)
            setCountyId('')
            setSelectedFile(null)
            //const newDocs = [...existingDocs, ...result.files]
            //setExistingDocs(newDocs)
            //queryClient.invalidateQueries('project')
        }, ' Upload Successful ')
    })

    const handleUploadDocs = () => {
        uploadDocs.mutate({ files: docsToUpload.map(x => x.file), countyId })
        setDocsToUpload([])
    }

    return (
        <>
            <h5>CAD</h5>
            <hr />

            <div style={{width:'200px'}}>
                <CountySelect className="mb-3" label="Select County" placeholder='County' isClearable
                    value={countyId} onChange={setCountyId}
                />
            </div>
            <div className="mb-3">
                <UploadFiles filesToUpload={docsToUpload} setFilesToUpload={setDocsToUpload} label='Select Documents' fileExtensions={['.csv']} />
                <Button size='sm' className="ms-2" disabled={!(docsToUpload.length && countyId) || uploadDocs.isLoading}
                    onClick={handleUploadDocs}
                >
                    {uploadDocs.isLoading ? 'Uploading' : 'Upload'}
                </Button>
            </div>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            {cadUploads && cadUploads.length > 0 ?
                                <SortableTable
                                    responsive
                                    data={cadUploads}
                                    setData={setCadUploads}
                                    columns={initColumns(cadUploads, ['id'])}
                                    addProps={{
                                        tHeading: { className: 'px-2', style: { whiteSpace: 'nowrap', } },
                                    }}
                                    columnRender={[
                                        { column: 'processedDate', render: (value) => toLocalDateString(value, 'dd-MM-yyyy') },
                                        { column: 'createdDate', render: (value) => toLocalDateString(value, 'dd-MM-yyyy') },
                                        { column: 'updatedDate', render: (value) => toLocalDateString(value, 'dd-MM-yyyy') },
                                    ]}
                                    
                                />
                                :
                                <div className="px-2">
                                    <p><i>No data found.</i></p>
                                </div>
                            }
                            {(isFetching) &&
                                <OverlayLoader />
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CADPage;