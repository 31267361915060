import http from './http'
import { getToken } from './auth'

const base = 'Uploads'

export async function uploadMonthlyOilPrice({files}) {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + getToken()
        }
    }
    // if no payload just return success statusCode
    if(!files || files.length === 0) {
        return { statusCode: 200 }
    }

    const formData = new FormData()
    files.forEach((x) => {
        formData.append('files', x)
    })

    const { data } = await http.post(`${base}/oil-price`, formData, config)
    return data
}

export async function uploadCAD({files, countyId}) {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + getToken()
        }
    }
    // if no payload just return success statusCode
    if(!files || files.length === 0) {
        return { statusCode: 200 }
    }

    const formData = new FormData()
    files.forEach((x) => {
        formData.append('files', x)
    })

    const { data } = await http.post(`${base}/cad/${countyId}`, formData, config)
    return data
}