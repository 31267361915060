import { subYears } from 'date-fns';
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import DatePicker from '../../components/forms/formDatePicker';
import CountySelect from '../selects/countySelect';
import LeaseSelect from '../selects/leaseSelect';
import OperatorSelect from '../selects/operatorSelect';

const ProductionsFilter = ({ isFiltering, onFilter, filters, fetch }) => {

    const [countyId, setCountyId] = useState(filters[0] ?? '')
    const [operatorId, setOperatorId] = useState(filters[1] ?? '')
    const [leaseId, setLeaseId] = useState(filters[2] ?? '')
    const [view, setView] = useState(filters[3] ?? '')
    const [startDate, setStartDate] = useState(filters[4] ?? subYears(new Date(), 5).setMonth(0))
    const [endDate, setEndDate] = useState(filters[5] ?? new Date())
    const queryClient = useQueryClient()

    const handleFilter = (e) => {
        e.preventDefault()
        e.stopPropagation()

        console.log(operatorId)

        const _filters = [
            countyId ?? '',
            operatorId ?? '',
            leaseId ?? '',
            view ?? '',
            startDate ?? '',
            endDate ?? '',
        ]
        onFilter(_filters)
        fetch(_filters)
    }

    const handleReset = () => {
        onFilter(['', '', '', '', ''])
        setCountyId('')
        setOperatorId('')
        setLeaseId('')
        setStartDate('')
        setEndDate('')
        queryClient.invalidateQueries(['productions', 'paginated'])
    }

    return (
        <div className="mb-3">

            <Form onSubmit={handleFilter}>
                <Card className='mt-2'>
                    <Card.Body>
                        <Row className="mb-2">
                            <Col lg="8">
                                <Row>
                                    <Col lg="4">
                                        <CountySelect className="mb-2" label='County' placeholder='County'
                                            value={countyId} onChange={setCountyId} isClearable isMulti
                                        />
                                    </Col>
                                    <Col lg="4">
                                        {/* <FormInput label="Operator" value={operator} onChange={(e) => setOperator(e.target.value)} /> */}
                                        <OperatorSelect className="mb-2" label='Operator' placeholder='Search Operator'
                                            value={operatorId} setOperatorId={setOperatorId} isMulti
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <LeaseSelect className="mb-2" label='Lease' placeholder='Search Lease'
                                            value={leaseId} setLeaseId={setLeaseId} isMulti
                                        />
                                    </Col>
                                    {/* <Col lg="3">
                                        <FormSelect
                                            label={"View By"}
                                            value={{ value: view, label: view }}
                                            options={[{ value: "Operator", label: "Operator" }, { value: "Lease", label: "Lease" }]}
                                            onChange={(option) => setView(option.value)}
                                        />
                                    </Col> */}
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col lg="4" className='mb-2'>
                                        <DatePicker className='form-control' label='Date From' placeholderText='Filter Date From' showMonthYearPicker dateFormat="MM/yyyy"
                                            selected={startDate} onChange={setStartDate} maxDate={endDate || undefined}
                                        />
                                    </Col>
                                    <Col lg="4" className='mb-2'>
                                        <DatePicker className='form-control' label='Date To' placeholderText='Filter Date To' showMonthYearPicker dateFormat="MM/yyyy"
                                            selected={endDate} onChange={setEndDate} minDate={startDate || undefined}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>&nbsp;</Form.Label>
                                        </Form.Group>
                                        <Button type='submit' disabled={isFiltering} >{isFiltering ? 'Searching...' : "Search"}</Button>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                    </Card.Body>
                </Card>
            </Form>

        </div>

    )
}

export default ProductionsFilter