import React, { useState } from 'react';
import SortableTable from 'reactstrap-sortable-table';
import { initColumns } from '../../utils/helper';

const OwnerTab = ({tabOwners}) => {

    const [owners, setOwners] = useState(tabOwners)

    return (
        <>
            {owners && owners.length > 0 ?
                <SortableTable
                    responsive
                    data={owners}
                    setData={setOwners}
                    columns={initColumns(owners, ['id'])}
                    withTotalColumns={['interest']}
                    totalRender={(value) => <strong className="text-bold">{value}</strong>}
                    addProps={{
                        tHeading: { className: 'px-2', style: { whiteSpace: 'nowrap', } },
                    }}
                />
                :
                <div className="px-2">
                    <p><i>No data found.</i></p>
                </div>
            }
        </>
    );
};

export default OwnerTab;