import { format } from 'date-fns';
import { Alert } from 'react-bootstrap';
import { getCurrentUser } from "../services/auth";

//convert object to query string 
//eg: objectToQueryString({ prop:'prop', anotherProp: 'anotherProp' }) = 'prop=prop&anotherProp=anotherProp'
export const objectToQueryString = (obj) => Object.keys(obj).map(key => key + '=' + obj[key]).join('&')

export function debounce(func, milliseconds = 1000) {

  let timer = null;

  return function (...args) {
    const onComplete = () => {
      func.apply(this, args);
      timer = null;
    }

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(onComplete, milliseconds);
  };
}

export const displayAddress = (address) => {
  let _address = []
  address.street && _address.push(address.street)
  address.city && _address.push(address.city)
  address.province && _address.push(address.province)
  address.zipCode && _address.push(address.zipCode)
  address.country && _address.push(address.country)

  return _address.join(', ')
}

//convert text to title case
//eg: toTitleCase('titleCase') = 'Title Case'
export const toTitleCase = (text) =>
  text.replace('_',' ').replace(/([A-Z])/g, " $1").replace(/^./, (match) => match.toUpperCase())

export const initColumns = (
  data, // an array of objects
  excludes = [] // object property that you dont want to be included
) => {
  if (data.length) {
    const firstItem = data[0]
    const columns = Object.keys(firstItem).map(prop => {
      return {
        value: prop,
        label: toTitleCase(prop)
      }
    }).filter(prop => !excludes.includes(prop.value))

    return columns
  }
  return []
}

//@params (columns = array of strings)
export const createColumns = (columns) => {
  return columns.map(col =>
    ({ value: col, label: toTitleCase(col) })
  )
}

export const isObjectEmpty = (obj) => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export const pesoFormat = (number) => {
  number = number || 0
  return number.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
}

export const usdFormat = (number) => {
  number = number || 0
  return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export const toLocalDateString = (date, stringformat = 'MM/dd/yyyy') => date && format(new Date(date + 'Z'), stringformat)

export const toLocalDateObject = (date) => date && new Date(date + 'Z')

export const statusColorCoding = (status) => {
  if (!status) return

  var statusClass = ''
  if (status.toLowerCase() === 'reservation')
    statusClass = 'text-warning'
  if (status.toLowerCase() === 'downpayment' || status.toLowerCase() === 'payment ongoing')
    statusClass = 'text-info'
  if (status.toLowerCase() === 'past due')
    statusClass = 'text-danger'
  if (status.toLowerCase() === 'fully paid')
    statusClass = 'text-success'
  if (status.toLowerCase() === 'terminated')
    statusClass = 'text-muted'

  return (
    <span className={statusClass}>{status}</span>
  )
}

export const statusAlert = (status) => {
  if (!status) return

  var statusClass = ''
  if (status.toLowerCase() === 'reservation')
    statusClass = 'warning'
  if (status.toLowerCase() === 'downpayment' || status.toLowerCase() === 'payment ongoing')
    statusClass = 'info'
  if (status.toLowerCase() === 'past due')
    statusClass = 'danger'
  if (status.toLowerCase() === 'fully paid')
    statusClass = 'success'
  if (status.toLowerCase() === 'terminated')
    statusClass = 'dark'

  return (
    <Alert variant={statusClass} className="mb-0 p-0">
      <h5 className="p-3 m-0">{status.toUpperCase()}</h5>
    </Alert>
  )
}

// remove props from obj1 that is not found in obj2
export const trimProps = (obj1, obj2, excludes = []) => {
  const newObj = { ...obj1 }

  for (let key in newObj) {
    if (!excludes.includes(key) && !(key in obj2))
      delete newObj[key];
  }
  return newObj
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const roundDecimal = (value, decimalPlaces) => {
  if (isNaN(value) || value == Number.POSITIVE_INFINITY) return 0.0
  return value.toFixed(decimalPlaces).replace(/[.,]00$/, "");
}

export const roundDecimalWithCommas = (value, decimalPlaces) => {
  return numberWithCommas(roundDecimal(value, decimalPlaces))
}
// round number to two decimals
export const toTwoDecimals = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
}

export const isAdmin = (user) => {
  const currentUser = user ?? getCurrentUser()
  if (!currentUser) return
  const roles = currentUser['roles-comma-separated'].split(',').map(item => item.trim())
  return roles.includes('Admin')
}

export const isUserVerified = (user) => {
  const currentUser = user ?? getCurrentUser()
  if (!currentUser) return
  return currentUser.Verified == "True" ? true : false
}

// convert properties of object to local date object
export const toLocalDateObjectProps = (obj, props = []) => {
  const newObj = { ...obj }

  for (const prop of props) {
    newObj[prop] = newObj[prop] && toLocalDateObject(newObj[prop])
  }

  return newObj
}

export const isDataString = (data) => {
  if (typeof data === 'string' || data instanceof String)
    return true
  else
    return false
}