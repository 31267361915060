import { subYears } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SortableTable from "reactstrap-sortable-table";
import ProductionsFilter from '../components/filters/productionsFilter';
import OverlayLoader from '../components/overlayLoader';
import Pages from '../components/pages';
import { getPaginatedProductions } from '../services/productions';
import { initColumns } from '../utils/helper';
import { onQuerySuccess } from '../utils/queryResponse';
import { format } from 'date-fns'

const HomePage = () => {

    const [searchParams] = useSearchParams();
    const countyId = searchParams.get('countyId')
    const operatorId = searchParams.get('operatorId')
    const leaseId = searchParams.get('leaseId')
    const viewBy = searchParams.get('viewBy')
    const startDate = searchParams.get('startDate')
    const endDate = searchParams.get('endDate')

    const [productions, setProductions] = useState([])

    const [pagination, setPagination] = useState()
    const [pageNumber, setPageNumber] = useState(1)

    const [pageSize, setPageSize] = useState(10)
    const [filters, setFilters] = useState([countyId ?? '', operatorId ?? '', leaseId ?? '', viewBy ?? '', startDate ? new Date(startDate) : subYears(new Date(), 5).setMonth(0), endDate ? new Date(endDate) : new Date()])

    const [hasSearched, setHasSearched] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const isUnmounted = useRef(null)

    const navigate = useNavigate()

    useEffect(() => {
        isUnmounted.current = false

        window.onpopstate = () => {
            window.location.reload()
        }

        if ((filters[0] || filters[1] || filters[2]) && (filters[3] || filters[4] || filters[5])) {
            fetchProductions(filters)
        }

        return () => {
            isUnmounted.current = true
        }
    }, [])

    const fetchProductions = async (filters = filters) => {
        setHasSearched(true)
        setIsLoading(true)

        console.log(filters[0], filters[1])

        navigate(`/home?${filters[0] && 'countyId=' + filters[0]}${filters[1] && '&operatorId=' + filters[1]}${filters[2] && '&leaseId=' + filters[2]}${filters[3] && '&viewBy=' + filters[3]}${filters[4] && '&startDate=' + format(filters[4], 'MM/dd/yyyy')}${filters[5] && '&endDate=' + format(filters[5], 'MM/dd/yyyy')}`)

        const data = await getPaginatedProductions(pageNumber, pageSize, ...filters)
        if (isUnmounted.current) return;

        onQuerySuccess(data, (result) => {
            console.log(result)
            setPagination(result)
            setProductions(result.data)
        })
        setIsLoading(false)
    }

    const changePage = async (pageNumber) => {
        setIsLoading(true)
        const data = await getPaginatedProductions(pageNumber, pageSize, ...filters)
        if (isUnmounted.current) return;

        onQuerySuccess(data, (result) => {
            setPagination(result)
            setProductions(result.data)
        })
        setIsLoading(false)
    }

    const handleSetPageSize = async (pageSize) => {
        setIsLoading(true)
        setPageSize(pageSize)
        const data = await getPaginatedProductions(pageNumber, pageSize, ...filters)
        if (isUnmounted.current) return;

        onQuerySuccess(data, (result) => {
            setPagination(result)
            setProductions(result.data)
        })
        setIsLoading(false)
    }

    return (
        <div className="position-relative">

            <Row>
                <Col>
                    <ProductionsFilter onFilter={setFilters} filters={filters} fetch={fetchProductions} />
                </Col>
            </Row>

            {hasSearched &&
                <>
                    <Row className="mb-2">
                        <Col>
                            <div className="text-right d-flex justify-content-lg-end">
                                <Form.Control as="select" className="w-auto d-inline mr-2" size="sm" onChange={(e) => handleSetPageSize(e.target.value)} defaultValue="10">
                                    <option value="" disabled>Select Page Size</option>
                                    <option value="2">2 per page</option>
                                    <option value="10">10 per page</option>
                                    <option value="20">20 per page</option>
                                    <option value="50">50 per page</option>
                                    <option value="100">100 per page</option>
                                    <option value="250">250 per page</option>
                                    <option value="500">500 per page</option>
                                </Form.Control>
                            </div>
                        </Col>
                    </Row>

                    <Card>
                        <Card.Body>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        {productions.length > 0 ?
                                            <>
                                                <div className="print-wrapper">
                                                    <SortableTable
                                                        responsive
                                                        size="sm"
                                                        data={productions}
                                                        setData={setProductions}
                                                        columns={initColumns(productions, ['id', 'leaseId', 'operatorId'])}
                                                        columnRender={[
                                                            {
                                                                column: 'operatorNumber', render: (value, column, columnIndex, data, dataIndex) =>
                                                                    <Link to={`/productions/operator/${productions[dataIndex].operatorId}?county=${productions[dataIndex].county}&countyId=${productions[dataIndex].countyId}&operatorName=${productions[dataIndex].operatorName}&operatorNumber=${productions[dataIndex].operatorNumber}&startDate=${format(new Date(filters[4]), 'MM/dd/yyyy')}&endDate=${format(filters[5], 'MM/dd/yyyy')}`}
                                                                        // state={{
                                                                        //     data: {
                                                                        //         county: productions[dataIndex].fieldName,
                                                                        //         operatorName: productions[dataIndex].operatorName,
                                                                        //         operatorNumber: productions[dataIndex].operatorNumber,
                                                                        //         startDate: format(new Date(filters[4]), 'MM/dd/yyyy'),
                                                                        //         endDate: format(filters[5], 'MM/dd/yyyy')
                                                                        //     }
                                                                        // }}
                                                                        >{value}
                                                                    </Link>
                                                            },
                                                            {
                                                                column: 'operatorName', render: (value, column, columnIndex, data, dataIndex) =>
                                                                    <Link to={`/productions/operator/${productions[dataIndex].operatorId}?county=${productions[dataIndex].county}&countyId=${filters[0]}&operatorName=${productions[dataIndex].operatorName}&operatorNumber=${productions[dataIndex].operatorNumber}&startDate=${format(new Date(filters[4]), 'MM/dd/yyyy')}&endDate=${format(filters[5], 'MM/dd/yyyy')}`}
                                                                        >{value}
                                                                    </Link>
                                                            },
                                                            {
                                                                column: 'leaseNumber', render: (value, column, columnIndex, data, dataIndex) =>
                                                                    <Link to={`/productions/lease/${productions[dataIndex].leaseId}?county=${productions[dataIndex].county}&leaseName=${productions[dataIndex].leaseName}&leaseNumber=${productions[dataIndex].leaseNumber}&startDate=${format(new Date(filters[4]), 'MM/dd/yyyy')}&endDate=${format(filters[5], 'MM/dd/yyyy')}`}
                                                                        >{value}
                                                                    </Link>
                                                            },
                                                            {
                                                                column: 'leaseName', render: (value, column, columnIndex, data, dataIndex) =>
                                                                    <Link to={`/productions/lease/${productions[dataIndex].leaseId}?county=${productions[dataIndex].county}&leaseName=${productions[dataIndex].leaseName}&leaseNumber=${productions[dataIndex].leaseNumber}&startDate=${format(new Date(filters[4]), 'MM/dd/yyyy')}&endDate=${format(filters[5], 'MM/dd/yyyy')}`}
                                                                        >{value}
                                                                    </Link>
                                                            }
                                                        ]}

                                                        withTotalColumns={['oilProduction', 'oilDisposition', 'gasProduction', 'gasDisposition']}
                                                        totalRender={(value) => <strong className="text-bold">{value}</strong>}
                                                        addProps={{ tBodyRow: { className: 'align-middle' } }}
                                                        dateColumns={['date']}
                                                    />
                                                </div>
                                                <Pages paginationData={pagination} changePage={changePage} />
                                            </> :
                                            <>
                                                <hr />
                                                <span className="fst-italic">no productions found</span>
                                            </>
                                        }

                                        {(isLoading) &&
                                            <OverlayLoader />
                                        }
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </>
            }

        </div>
    );
};

export default HomePage;